@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


.container {
    margin-bottom: 5rem;
}

.header {
    width: 90%;
    word-break: break-all;
    /* 允许在单词（包括中文字符）内断行 */
    white-space: normal;
    /* 允许换行 */
    overflow-wrap: break-word;
    /* 对长单词进行折行处理 */
}

.item {
    height: auto;
}

.title {
    margin-top: 2rem;
    text-align: center;
    font-family: "Ubuntu", system-ui;
    font-size: 1.5rem;
}

.description {
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.video {
    display: flex;
    justify-items: center;
    width: 90% !important;
    margin-left: 5%;
    margin: 0 auto;
    /* 水平居中 */
    /* height: auto; */
    /* 自动调整高度 */
}

.video iframe {
    display: block;
    margin: 0 auto;
    height: 30vh !important;
    box-shadow: 0 16px 1.5rem rgba(0, 0, 0, 0.3);
}