@font-face {
  font-family: "iconfont"; /* Project id 4655247 */
  src: url('//at.alicdn.com/t/c/font_4655247_t4ru0mw8agt.woff2?t=1732126426818') format('woff2'),
       url('//at.alicdn.com/t/c/font_4655247_t4ru0mw8agt.woff?t=1732126426818') format('woff'),
       url('//at.alicdn.com/t/c/font_4655247_t4ru0mw8agt.ttf?t=1732126426818') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xuewei:before {
  content: "\e613";
}

.icon-phone:before {
  content: "\e725";
}

.icon-24gf-forward:before {
  content: "\ea7c";
}

.icon-ditu:before {
  content: "\e625";
}

.icon-152:before {
  content: "\e779";
}

.icon-xiangyoujiantou:before {
  content: "\e65f";
}

.icon-icon-48pt-blingbling:before {
  content: "\e65d";
}